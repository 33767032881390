import React, { useState, useEffect } from 'react';

import { mediumPageSizePreference, defaultWrapLinesPreference, paginationLabels } from 'src/commons/tables';

import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  TextFilter,
  Table,
} from '@amzn/awsui-components-react-v3';

import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';

import { Link } from 'react-router-dom';
import { createMetricDefinitionLink } from 'src/routes/Paths';
import { listAllMetricMetadata } from 'src/api/metricstore';
import { MetricStorePageHeader } from 'src/components/metricstore/components';
import { UnauthorizedModal } from 'src/components/metricstore/components-V3';
import { EmptyState } from 'src/commons/EmptyState';
import { dateString } from 'src/components/metricstore/helpers';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

export interface MetricsCatalogProps {
  setContentType: any;
}

export const MetricsCatalog = (props: MetricsCatalogProps) => {
  const [allItems, setAllItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableMessage, setTableMessage] = useState('No metric');
  const [onboardModal, setOnboardModal] = useState(false);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 25,
  });

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'name',
      header: 'Name',
      cell: (item) => <Link to={createMetricDefinitionLink(item.id)}>{item.name}</Link>,
      minWidth: 150,
      sortingField: 'name',
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item) => item.description,
      minWidth: 150,
      sortingField: 'description',
    },
    {
      id: 'ownerTeam',
      header: 'Owner team',
      cell: (item) => item.ownerTeam,
      minWidth: 150,
      sortingField: 'ownerTeam',
    },
    {
      id: 'lastRun',
      header: 'Last refreshed at',
      cell: (item) => dateString(item.lastRefreshedAt),
      minWidth: 150,
      sortingField: 'lastRun',
    },
    {
      id: 'isArchived',
      header: 'Status',
      cell: (item) => (item.isArchived ? 'Inactive' : 'Active'),
      minWidth: 150,
      sortingField: 'isArchived',
    },
  ];

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  /**
   * Asynchronously fetches and stores the metrics catalog
   */
  const handleRefresh = async () => {
    setLoading(true);

    try {
      const listAllMetricMetadataResponse = await listAllMetricMetadata({});
      setAllItems(listAllMetricMetadataResponse.metrics);
    } catch (err) {
      if (err.name == 'InsufficientPermissionException') {
        setOnboardModal(true);
      }
      setTableMessage(`No metric to display.`);
    }
    setLoading(false);
  };

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(allItems, {
    filtering: {},
    pagination: { pageSize: 25 },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'name',
        },
      },
    },
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  return (
    <>
      <UnauthorizedModal visible={onboardModal} onClose={() => setOnboardModal(false)} />

      <Table
        {...collectionProps}
        loadingText='Loading metrics catalog...'
        loading={loading}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <>
            <MetricStorePageHeader
              buttons={[
                {
                  text: '',
                  icon: 'refresh',
                  onItemClick: handleRefresh,
                },
                {
                  text: 'Request access',
                  variant: 'primary',
                  disable: true,
                  onItemClick: () => {},
                },
              ]}
              subheader={
                <>
                  Metrics Catalog
                  <span className='awsui-util-header-counter'>{` (${items.length})`}</span>
                </>
              }
            />
          </>
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Search metrics'
            filteringPlaceholder='Search metrics'
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
        empty={<EmptyState title={tableMessage} subtitle='No metrics' />}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={mediumPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        selectionType='single'
      />
    </>
  );
};
