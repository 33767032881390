export function getActiveHref() {
  const path = window.location.pathname + '/';
  if (path.includes('publisher')) return '/publisher/tables'; // Setting an exception as the publisher route is compound.
  return path.substring(0, path.indexOf('/', 1));
}

export function isHomePage() {
  return getActiveHref() == '/';
}

export function isWorkspacePath(path) {
  return (
    path.startsWith('/workspace') ||
    path.startsWith('/tags') ||
    path.startsWith('/search') ||
    path.startsWith('/adv-search') ||
    path.startsWith('/templates') ||
    path.startsWith('/notif') ||
    path.startsWith('/advisories')
  );
}

export function isGroupLevelPath(path) {
  return (
    !isWorkspacePath(path) ||
    path.startsWith('/search') ||
    path.startsWith('/adv-search') ||
    path.startsWith('/current-workspaces') ||
    path.startsWith('/workspaces-datasets') ||
    path.startsWith('/workspaces-databases') ||
    path.startsWith('/workspaces-catalogs') ||
    path.startsWith('/workspaces-permission') ||
    path.startsWith('/advisories')
  );
}
