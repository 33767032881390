import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  PropertyFilter,
  Table,
} from '@amzn/awsui-components-react-v3';
import { Link } from 'react-router-dom';
import { PageHeader } from 'src/components/notifications/common';
import { defaultWrapLinesPreference, i18nStrings, largePageSizePreference, paginationLabels } from 'src/commons/tables';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { listDataSets } from 'src/api/catalog';
import {
  DATA_LOAD_LIMIT,
  GALAXI_DATASOURCE_ID,
  LAKE_FORMATION_DATASOURCE_ID,
  REDSHIFT_DATASOURCE_ID,
  TABLE_CONTENT_TYPE,
} from 'src/commons/constants';
import { isValidWorkspace } from 'src/commons/validationUtils';
import {
  createGroupDetailLink,
  createWorkspaceDetailLink,
  createWSDatasetDetailLink,
  createWSGlueCatalogDetailLink,
  createWSGlueDatabaseDetailLink,
  createWSRedshiftCatalogDetailLink,
  createWSRedshiftDatabaseDetailLink,
} from 'src/routes';
import { typeToHumanReadableObject } from 'src/components/permissions/myDatasets/common';
import { DefaultRouteProps, getGroupOrWorkspaceName } from 'src/commons/common';

export interface WSBrowseDatasetsProps extends DefaultRouteProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  catalogMap: any;
}

export const WSBrowseDatasets = (props: WSBrowseDatasetsProps) => {
  const [allItems, setItems] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [nextToken, setNextToken] = useState(null);
  const handleRefresh = async () => {
    setLoadingTable(true);
    const dataSets = await listDataSets({ Limit: DATA_LOAD_LIMIT, IncludeTotalCount: true });
    setTotalCount(dataSets.TotalCount);
    setNextToken(dataSets.NextToken);
    if (dataSets.NextToken == null) {
      setAllDataLoaded(true);
    }
    let datasetList = dataSets.DataSetList;
    let publicAndRestrictedDataSets = [];
    let privateDataSets = [];
    for (let dataset of datasetList) {
      if (dataset?.IdInfo?.ClusterIdentifier != null) {
        let catalogName = props.catalogMap.get(
          dataset?.IdInfo?.CatalogId + ':' + dataset?.IdInfo?.ClusterIdentifier + ':' + dataset?.IdInfo?.Region,
        );
        dataset['CatalogName'] = catalogName == null ? dataset?.IdInfo?.CatalogId : catalogName;
      } else {
        let catalogName = props.catalogMap.get(dataset?.IdInfo?.CatalogId + ':' + dataset?.IdInfo?.Region);
        dataset['CatalogName'] = catalogName == null ? dataset?.IdInfo?.CatalogId : catalogName;
      }
      if (dataset.DataClassification === 'Public' || dataset.DataClassification === 'Restricted') {
        publicAndRestrictedDataSets.push(dataset);
      } else {
        if (dataset.Owners.includes(props.activeWorkspace.workspaceId)) {
          privateDataSets.push(dataset);
        }
      }
    }
    datasetList = publicAndRestrictedDataSets.concat(privateDataSets);
    setItems(datasetList);
    setLoadingTable(false);
  };

  const loadMoreData = async () => {
    setLoadingTable(true);
    const dataSets = await listDataSets({ NextToken: nextToken, Limit: DATA_LOAD_LIMIT });
    setNextToken(dataSets.NextToken);
    let publicAndRestrictedDataSets = [];
    let privateDataSets = [];
    let datasetList = dataSets.DataSetList;
    for (let dataset of datasetList) {
      if (dataset?.IdInfo?.ClusterIdentifier != null) {
        let catalogName = props.catalogMap.get(
          dataset?.IdInfo?.CatalogId + ':' + dataset?.IdInfo?.ClusterIdentifier + ':' + dataset?.IdInfo?.Region,
        );
        dataset['CatalogName'] = catalogName == null ? dataset?.IdInfo?.CatalogId : catalogName;
      } else {
        let catalogName = props.catalogMap.get(dataset?.IdInfo?.CatalogId + ':' + dataset?.IdInfo?.Region);
        dataset['CatalogName'] = catalogName == null ? dataset?.IdInfo?.CatalogId : catalogName;
      }
      if (dataset.DataClassification === 'Public' || dataset.DataClassification === 'Restricted') {
        publicAndRestrictedDataSets.push(dataset);
      } else {
        if (dataset.Owners.includes(props.activeWorkspace.workspaceId)) {
          privateDataSets.push(dataset);
        }
      }
    }
    datasetList = publicAndRestrictedDataSets.concat(privateDataSets);
    let currentItems = allItems;
    currentItems.push(...datasetList);
    setItems(currentItems);
    if (dataSets.NextToken == null) {
      setAllDataLoaded(true);
    }
    setLoadingTable(false);
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 25,
  });

  const flatten = (items) => {
    let list = [];
    for (let item of items) {
      if (item.DataSourceType == LAKE_FORMATION_DATASOURCE_ID || item.DataSourceType == GALAXI_DATASOURCE_ID) {
        list.push({
          datasetName: item.DataSetName,
          databaseName: item.IdInfo?.DatabaseName,
          catalogId: item.IdInfo?.CatalogId,
          catalogName: item.CatalogName,
          Id: item.Id,
          owner: item.Owners[0],
          region: item.IdInfo?.Region,
          datasourceType: item.DataSourceType,
          type: typeToHumanReadableObject[LAKE_FORMATION_DATASOURCE_ID],
        });
      } else if (item.DataSourceType == REDSHIFT_DATASOURCE_ID) {
        list.push({
          datasetName: item.DataSetName,
          databaseName: item.IdInfo?.DatabaseName,
          catalogId: item.IdInfo?.CatalogId,
          catalogName: item.CatalogName,
          Id: item.Id,
          owner: item.Owners[0],
          region: item.IdInfo?.Region,
          schemaName: item.IdInfo.SchemaName,
          clusterIdentifier: item.ClusterIdentifier,
          datasourceType: item.DataSourceType,
          type: typeToHumanReadableObject[REDSHIFT_DATASOURCE_ID],
        });
      }
    }
    return list;
  };

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'datasetName',
      header: 'Dataset name',
      cell: (item) => <Link to={createWSDatasetDetailLink(item?.Id)}>{item.datasetName}</Link>,
      sortingField: 'datasetName',
      minWidth: '200px',
    },
    {
      id: 'databaseName',
      header: 'Database name',
      cell: (item) =>
        item.datasourceType == REDSHIFT_DATASOURCE_ID ? (
          <Link
            to={createWSRedshiftDatabaseDetailLink(
              item?.catalogId,
              item?.clusterIdentifier,
              item?.databaseName,
              item?.region,
            )}
          >
            {item.databaseName}
          </Link>
        ) : (
          <Link to={createWSGlueDatabaseDetailLink(item?.catalogId, item?.databaseName, item?.region)}>
            {item.databaseName}
          </Link>
        ),
      sortingField: 'databaseName',
      minWidth: '200px',
    },
    {
      id: 'catalogName',
      header: 'Catalog name',
      cell: (item) =>
        item.clusterIdentifier != null ? (
          <Link to={createWSRedshiftCatalogDetailLink(item?.catalogId, item?.clusterIdentifier, item?.region)}>
            {item.catalogName}
          </Link>
        ) : (
          <Link to={createWSGlueCatalogDetailLink(item?.catalogId, item?.region)}>{item.catalogName}</Link>
        ),
      sortingField: 'catalogName',
      minWidth: '200px',
    },
    {
      id: 'owner',
      header: 'Owner',
      cell: (item) => (
        <Link
          to={isValidWorkspace(item.owner) ? createWorkspaceDetailLink(item.owner) : createGroupDetailLink(item.owner)}
        >
          {getGroupOrWorkspaceName(item.owner, props.workspaceNameMap)}
        </Link>
      ),
      sortingField: 'owner',
      minWidth: '200px',
    },
    {
      id: 'type',
      header: 'Type',
      cell: (item) => item.type,
      minWidth: '100px',
    },
  ];

  const { items, collectionProps, paginationProps, propertyFilterProps, filteredItemsCount } = useCollection(
    flatten(allItems),
    {
      filtering: {
        noMatch: (
          <div className='awsui-util-t-c'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>No matches</b>
            </div>
            <p className='awsui-util-mb-s'>We can’t find a match.</p>
          </div>
        ),
        empty: (
          <div className='awsui-util-t-c'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>No datasets</b>
            </div>
            <p className='awsui-util-mb-s'>No datasets were found in the catalog.</p>
          </div>
        ),
      },
      pagination: {
        pageSize: preferences.pageSize,
      },
      sorting: {},
      selection: {},
      propertyFiltering: {
        filteringProperties: [
          {
            propertyLabel: 'Catalog name',
            key: 'catalogName',
            groupValuesLabel: 'Catalog names',
          },
          {
            propertyLabel: 'Database name',
            key: 'databaseName',
            groupValuesLabel: 'Database names',
          },
          {
            propertyLabel: 'Schema name',
            key: 'schemaName',
            groupValuesLabel: 'Schema names',
          },
          {
            propertyLabel: 'Dataset name',
            key: 'datasetName',
            groupValuesLabel: 'Dataset names',
          },
          {
            propertyLabel: 'Owner',
            key: 'owner',
            groupValuesLabel: 'Owners',
          },
          {
            propertyLabel: 'Type',
            key: 'type',
            groupValuesLabel: 'Types',
          },
        ],
      },
    },
  );

  const handlePageChange = async (e) => {
    if (paginationProps.currentPageIndex == paginationProps.pagesCount && !allDataLoaded) {
      await loadMoreData();
    }
    if (
      paginationProps.currentPageIndex != paginationProps.pagesCount ||
      (e.detail.currentPageIndex <= paginationProps.pagesCount &&
        e.detail.currentPageIndex != paginationProps.currentPageIndex)
    ) {
      paginationProps.onChange(e);
    }
  };

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading datasets...'
        loading={loadingTable}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <>
            <PageHeader
              buttons={[]}
              header={
                <>
                  Datasets
                  <span className='awsui-util-header-counter'>{` (${totalCount})`}</span>
                </>
              }
            />
          </>
        }
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={paginationLabels}
            onChange={(e) => handlePageChange(e)}
            // currentPageIndex={currentPageIndex}
            openEnd={!allDataLoaded}
            disabled={loadingTable}
          />
        }
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={largePageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            disabled={true}
            i18nStrings={{
              ...i18nStrings,
              filteringPlaceholder: 'To discover datasets, use search page instead.',
            }}
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />
    </>
  );
};
