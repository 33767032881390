import * as React from 'react';
import { Component } from 'react';
import { Redirect } from 'react-router';
import { Flashbar, Select, Spinner, Table, Box, SpaceBetween } from '@amzn/awsui-components-react-v3';
import { DropdownStatusProps } from '@amzn/awsui-components-react-v3/polaris/internal/components/dropdown-status';
import { dataSetDetail, listCatalogs, listDataSets, syncDataSets } from '../../../src/api/catalog';
import { editDataLakeRole, listDataLakeRoleProperty, listDataPermissions } from '../../../src/api/permissions';
import { DataBadges, DetailsPageHeader } from './common';
import { flattenItem } from 'src/components/catalog/browsedatasets';
import {
  DATA_LAKE_CTI_URL,
  DATA_PERMISSION_CONSUMER_OPTION,
  DATA_PERMISSION_LAKE_FORMATION_TYPE,
  DATA_PERMISSION_STATUS_ACTIVE,
  TABLE_CONTENT_TYPE,
  TABLE_STATUS_DEPRECATED,
} from 'src/commons/constants';
import { createWSDatasetDetailLink, Page } from 'src/routes';
import {
  convertToDgsHCResourceArn,
  DefaultRouteProps,
  fetchTemplatesForResourceId,
  getCtiUrlFromCti,
  isDataLakeAdmin,
  isDGSAdmin,
} from 'src/commons/common';
import { DetailsContainer } from 'src/components/catalog/datasetdetail/detailsContainer';
import { Header, Tabs } from '@amzn/awsui-components-react-v3/polaris';
import { getImportantMetadata, getMoreMetadata } from 'src/components/catalog/datasetdetail/utils';
import { DatasetDetailsEditModal } from 'src/components/catalog/datasetdetail/editModal';
import IamConsumersTable from 'src/components/catalog/datasetdetail/iamConsumers';
import LineageTable from 'src/components/catalog/datasetdetail/lineageDetails';
import UsageStatistics from 'src/components/catalog/datasetdetail/usageStatisticsDetails';
import { DataConsumersTable } from 'src/components/permissions/myBaselining/dataConsumersTable';
import MetadataDetails from 'src/components/workspaces/common/metadataDetails';
import { AdvisoriesForResourceTable } from 'src/components/dataadvisory/listAdvisoriesPage/advisoriesForResource';
import { TemplatesForResourceDetailsTable } from 'src/components/templates/TemplatesForResourceDetailsTable';
import _ from 'lodash';
import { enableAdvisories } from 'src/api/config';
import { ContactInfo } from 'src/components/workspaces/common/ContactInfo';
import {
  generateMetaDataMap,
  getGlossariesForResource,
  metadataMapHasCTI,
} from 'src/components/workspaces/common/common';
import { DataQuality } from 'src/components/catalog/dataquality/dataQuality';
import { getDatasetUpperLevelResourceId } from 'src/components/utils/hybridCatalog/idUtil';
import { signedInWithWorkspace } from 'src/components/dataadvisory/constants';
import { AccessSelectModal } from 'src/components/workspaces/dataBrowse/dataset/accessSelectModal';

export interface DataSetDetailProps extends DefaultRouteProps {
  allowListed: boolean;
  setContentType: any;
  setActiveDatasetName: any;
  activeGroup: string;
  workspaceId: string;
  groupInfo: any;
  username: string;
  location: any;
  match: any;
  addToCart: any;
  cartItemIds: string[];
  groups: any[];
  userInfo: any;
}

export interface DataSetDetailState {
  options: object[];
  option: object;
  mostRecentOption: object;
  status: DropdownStatusProps.StatusType;
  notifications: object[];
  businessGlossaries: any[];
  accessSelectVisible: boolean;

  roleProperties: object;
  requestSending: boolean;

  dataPermissionList: object[];
  redirect: object;
  dataset: object;
  templates: object[];

  curNextToken: string;

  catalog: object;
  catalogLoading: boolean;

  buttonLoading: boolean;
  datasetDetailsLoading: boolean;

  editDataSetModalVisible: boolean;
}

export default class DataSetDetail extends Component<DataSetDetailProps, DataSetDetailState> {
  state = {
    templates: undefined,
    dataPermissionList: undefined,
    businessGlossaries: undefined,
    accessSelectVisible: false,

    editDataSetModalVisible: false,
    datasetDetailsLoading: true,
    buttonLoading: false,
    catalogLoading: true,
    catalog: null,
    options: [],
    option: null,
    mostRecentOption: null,
    status: 'loading' as DropdownStatusProps.StatusType,
    notifications: [],
    roleProperties: undefined,
    requestSending: false,
    redirect: undefined,
    curNextToken: null,

    dataset: {
      catalogInfo: undefined,
      LakeFormationRoleARN: undefined,
      KmsKey: undefined,
      DataClassification: undefined,
      Owners: undefined,
      DataPreview: undefined,
      IdInfo: {
        TableName: undefined,
        CatalogId: undefined,
        DatabaseName: undefined,
        Region: undefined,
        DataSourceId: undefined,
        ClusterIdentifier: undefined,
        SchemaName: undefined,
      },
      RefreshCadence: undefined,
      WheeljackConfidenceFileLocation: undefined,
      PII: undefined,
      SLA: undefined,
      TableState: undefined,
      DataSetName: undefined,
      DataSetDesc: undefined,
      Id: undefined,
      SupportedAccessTypes: undefined,
      DataAccessRole: undefined,
    },
  };

  columnDefinitions = [
    {
      id: 'name',
      header: 'Name',
      cell: (item) => item.Name,
      minWidth: '200px',
    },
    {
      id: 'type',
      header: 'Type',
      cell: (item) => item.Type,
      minWidth: '200px',
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item) => item.Comment,
      minWidth: '200px',
    },
  ];

  private LOAD_MORE_DATASETS_OPTION_ID: string = 'load-more-datasets';

  // display "Details" section on UI
  getDetailsContainer(version) {
    const metadata = this.getMetadata(version);

    return (
      <>
        <SpaceBetween size={'l'}>
          <DetailsContainer items={metadata} title={'Dataset details'} />
          {!this.state.datasetDetailsLoading && <ContactInfo resource={this.getArn()} />}
        </SpaceBetween>
      </>
    );
  }

  // display "Columns" section on UI
  getColumnsTable(version) {
    return (
      <Table
        loadingText='Loading resources'
        columnDefinitions={this.columnDefinitions}
        items={version?.Columns || []}
        resizableColumns
        header={<Header counter={version?.Columns?.length ? '(' + version.Columns.length + ')' : '0'}>Columns</Header>}
        empty={
          <Box textAlign='center' variant='p'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>No columns</b>
            </div>
            <p className='awsui-util-mb-s'>No columns to display.</p>
          </Box>
        }
        loading={this.state.status === 'loading'}
      />
    );
  }

  // display "Partition Keys" section on UI
  getPartitionKeysTable(version) {
    return (
      <Table
        loadingText='Loading resources'
        columnDefinitions={this.columnDefinitions}
        items={version?.PartitionKeys || []}
        resizableColumns
        header={
          <Header counter={version?.PartitionKeys?.length ? `(${version.PartitionKeys.length})` : '(0)'}>
            Partition keys
          </Header>
        }
        empty={
          <Box textAlign='center' variant='p'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>No partition keys</b>
            </div>
            <p className='awsui-util-mb-s'>No partition keys to display.</p>
          </Box>
        }
        loading={this.state.status === 'loading'}
      />
    );
  }

  getTemplateForDataset() {
    return <TemplatesForResourceDetailsTable items={this.state.templates} userOwnsResource={this.userOwnsDataset()} />;
  }

  // display "IAM Consumers" section on UI
  // Once all IAM data permissions have been backfilled as DataPermission,
  // remove this and use only DataConsumersTable below.
  getIamAccessTable() {
    return (
      <IamConsumersTable
        dataset={this.state.dataset}
        datasetId={this.props.match.params.id}
        activeGroup={this.props.activeGroup}
        onSuccess={this.iamAccessNotifySuccess}
        onFailure={this.iamAccessNotifyFailure}
      />
    );
  }

  // display "Consumers" section on UI
  // Currently, it is only for GlueLF dataset in page /datasets/
  getDataConsumersTable() {
    if (this.state.dataset && this.state.dataset.IdInfo && this.state.dataset.IdInfo.DataSourceId) {
      const dataset = this.state.dataset;
      const dataSourceId = dataset.IdInfo.DataSourceId.charAt(0).toUpperCase() + dataset.IdInfo.DataSourceId.slice(1);
      const resource = {
        accountId: dataset.IdInfo.CatalogId,
        region: dataset.IdInfo.Region,
        type: 'TABLE',
        dataCatalogObjectDetails: {
          dataSourceId: dataSourceId,
          databaseName: dataset.IdInfo.DatabaseName,
          tableName: dataset.IdInfo.TableName,
        },
        dpType: DATA_PERMISSION_LAKE_FORMATION_TYPE,
        tagResourceId: dataset.Id,
        tagUpperLevelResourceId: getDatasetUpperLevelResourceId(dataset),
      };

      return <DataConsumersTable {...this.props} resource={resource} />;
    }
  }

  // display "Lineage" section on UI
  getLineage() {
    return (
      <LineageTable
        dataset={this.state.dataset}
        datasetId={this.props.match.params.id}
        activeGroup={this.props.activeGroup}
      />
    );
  }

  getDataQuality() {
    return <DataQuality setContentType={this.props.setContentType} datasetId={this.props.match.params.id} />;
  }

  // display "Metadata" section on UI

  getMetadataForDataset() {
    return (
      <MetadataDetails
        resourceOwnerIds={this.state.dataset?.Owners}
        resource={this.getArn()}
        activeGroup={this.props.activeGroup}
        setContentType={this.props.setContentType}
        setNotification={this.setNotification}
      />
    );
  }

  setNotification = async (header, message) => {
    if (header === 'error') {
      this.setState({
        notifications: [
          {
            header: header,
            type: 'error',
            content: message,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
    } else {
      this.setState({
        notifications: [
          {
            type: 'success',
            content: message,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
    }
  };

  // after revocation successfully goes through, create flashbar notification
  // indicating success
  iamAccessNotifySuccess() {
    this.setState({
      notifications: [
        {
          type: 'success',
          content: 'Successfully revoked the access.',
          dismissible: true,
          onDismiss: () => this.setState({ notifications: [] }),
        },
      ],
    });
  }

  // similarly, create a flashbar notifying failure
  iamAccessNotifyFailure() {
    this.setState({
      notifications: [
        {
          type: 'error',
          content: 'Failed to revoke the access.',
          dismissible: true,
          onDismiss: () => this.setState({ notifications: [] }),
        },
      ],
    });
  }

  versionDetail(version) {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    let tabs = [
      {
        label: 'Details',
        id: 'Details',
        content: this.getDetailsContainer(version),
      },
      {
        label: 'Columns',
        id: 'Columns',
        content: this.getColumnsTable(version),
      },
      {
        label: 'Partition keys',
        id: 'Partition keys',
        content: this.getPartitionKeysTable(version),
      },
      {
        label: 'Lineage',
        id: 'Lineage',
        content: this.getLineage(),
      },
      {
        label: 'Data quality',
        id: 'Data quality',
        content: this.getDataQuality(),
      },
      {
        label: 'Templates',
        id: 'Templates',
        content: this.getTemplateForDataset(),
      },
      {
        label: 'Metadata',
        id: 'Metadata',
        content: this.getMetadataForDataset(),
      },
    ];
    if (this.state.dataset?.SupportedAccessTypes?.includes('IAM') && this.userOwnsDataset()) {
      tabs.push({
        label: 'IAM consumers',
        id: 'IAM consumers',
        content: this.getIamAccessTable(),
      });
    } else {
      tabs.push({
        label: 'Usage statistics',
        id: 'UsageStats',
        content: this.getUsageStatistics(),
      });
    }

    if (!this.state.dataset?.SupportedAccessTypes?.includes('IAM') && this.userOwnsDataset()) {
      tabs.push({
        label: 'Consumers',
        id: 'Consumers',
        content: this.getDataConsumersTable(),
      });
    }

    if (enableAdvisories()) {
      tabs.push({
        label: 'Advisories',
        id: 'advisories',
        content: (
          <AdvisoriesForResourceTable
            resourceArn={this.getArn()}
            activeGroup={this.props.activeGroup}
            setContentType={this.props.setContentType}
          />
        ),
      });
    }

    return (
      <div>
        <Tabs tabs={tabs} />
      </div>
    );
  }

  getArn() {
    return convertToDgsHCResourceArn(
      this.state.dataset?.IdInfo.CatalogId,
      this.state.dataset?.IdInfo.ClusterIdentifier,
      this.state.dataset?.IdInfo.DatabaseName,
      this.state.dataset?.IdInfo.SchemaName,
      this.state.dataset?.IdInfo.TableName,
      undefined,
      this.state.dataset?.DataAccessRole,
    );
  }

  // Collects and returns all important metadata in an array
  getMetadata(version) {
    if (this.state.datasetDetailsLoading || this.state.catalogLoading) {
      const metadata = [];
      for (let i = 0; i < 13; i++) {
        metadata.push(<Spinner size='normal' />);
      }
      return metadata;
    }
    const importantMetadata = getImportantMetadata(this.state.dataset, this.state.catalog, version);
    const moreMetadata = getMoreMetadata(this.state.dataset, version, this.props.workspaceNameMap);

    const metadata = importantMetadata.concat(moreMetadata);
    return metadata;
  }

  handleRefresh = async () => {
    console.log(this.state.dataset);

    this.props.setContentType(TABLE_CONTENT_TYPE);
    this.loadRoleProperties = this.loadRoleProperties.bind(this);

    this.loadTableDataPermission = this.loadTableDataPermission.bind(this);
    // This is for Edit button, when clicked it opens a form with editable parameters
    this.editDataset = this.editDataset.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.iamAccessNotifySuccess = this.iamAccessNotifySuccess.bind(this);
    this.iamAccessNotifyFailure = this.iamAccessNotifyFailure.bind(this);

    const dataSet = await listDataSets({
      Filter: {
        IdList: [this.props.match.params.id],
      },
    });
    console.assert(dataSet.DataSetList.length != 0, 'Entry not found with ID: ' + this.props.match.params.id);
    console.assert(!(dataSet.DataSetList.length > 1), 'Multiple entries found with ID: ' + this.props.match.params.id);
    this.setState({
      dataset: dataSet.DataSetList[0],
      catalogLoading: true,
      datasetDetailsLoading: true,
    });
    if (dataSet.DataSetList[0]?.TableState == TABLE_STATUS_DEPRECATED) {
      this.setState({
        notifications: [
          {
            type: 'error',
            content: 'This dataset is deprecated.',
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
    }
    this.fetchCatalog();
    this.fetchDetails();
    await this.fetchTemplates();

    if (this.state.dataset) {
      this.props.setActiveDatasetName(this.state.dataset.DataSetName);
      this.setState({ businessGlossaries: await getGlossariesForResource(this.getArn()) });
    }
    await this.loadRoleProperties();
    await this.loadTableDataPermission();
  };

  componentDidMount = async () => {
    await this.handleRefresh();
  };

  // Second parameter can be previous state if needed
  componentDidUpdate = async (prevProps, _) => {
    if (prevProps.activeGroup !== this.props.activeGroup) {
      await this.loadRoleProperties();
      await this.loadTableDataPermission();
    }
    if (signedInWithWorkspace(this.props)) {
      this.setState({ redirect: { pathname: createWSDatasetDetailLink(this.props.match.params.id) } });
    }
  };

  getFormattedOptions = (listOfDataSetDetailResponses) => {
    const mappedList = listOfDataSetDetailResponses.map((i) => ({
      id: i.VersionId.toString(),
      label: 'Version ' + i.VersionId,
      item: i,
    }));
    mappedList.sort(function (a, b) {
      return b.item.VersionId - a.item.VersionId;
    });
    return mappedList;
  };

  loadRoleProperties = async () => {
    if (!this.props.allowListed) return;

    const roleProperties = await listDataLakeRoleProperty({
      groupId: this.props.activeGroup,
    });
    this.setState({
      roleProperties,
    });
  };

  fetchTemplates = async () => {
    if (!this.props.activeGroup) {
      return;
    }
    let templates = await fetchTemplatesForResourceId(this.state.dataset.Id);
    this.setState({
      templates: templates,
    });
  };

  loadTableDataPermission = async () => {
    if (!this.props.activeGroup) {
      return;
    }
    const catalogId = this.state.dataset?.IdInfo.CatalogId;
    const databaseName = this.state.dataset?.IdInfo.DatabaseName;
    const tableName = this.state.dataset?.IdInfo.TableName;

    let dataPermissionList = [];
    // check if the current group have All Tables access
    let tableResourceAllTables = {
      table: {
        databaseName: databaseName,
        name: null,
        catalogId: catalogId,
        tableWildcard: {},
      },
    };
    let listAllTablesDataPermissionsRequest = {
      ownerId: this.props.activeGroup,
      resource: tableResourceAllTables,
      region: this.state.dataset.IdInfo.Region,
      status: DATA_PERMISSION_STATUS_ACTIVE,
      option: DATA_PERMISSION_CONSUMER_OPTION,
      type: DATA_PERMISSION_LAKE_FORMATION_TYPE,
      nextToken: null,
    };
    let listAllTablesDataPermissionsResult = await listDataPermissions(listAllTablesDataPermissionsRequest);
    let allTablesDataPermissions = [...listAllTablesDataPermissionsResult.dataPermissionList];
    while (listAllTablesDataPermissionsResult.nextToken != null) {
      listAllTablesDataPermissionsRequest.nextToken = listAllTablesDataPermissionsResult.nextToken;
      listAllTablesDataPermissionsResult = await listDataPermissions(listAllTablesDataPermissionsRequest);
      allTablesDataPermissions.push(...listAllTablesDataPermissionsResult.dataPermissionList);
    }
    dataPermissionList.push(...allTablesDataPermissions);

    // check if the current group have single table access
    let tableResourceSingleTable = {
      table: {
        databaseName: databaseName,
        name: tableName,
        catalogId: catalogId,
        tableWildcard: null,
      },
    };
    let listSingleTableDataPermissionsRequest = {
      ownerId: this.props.activeGroup,
      resource: tableResourceSingleTable,
      region: this.state.dataset.IdInfo.Region,
      status: DATA_PERMISSION_STATUS_ACTIVE,
      option: DATA_PERMISSION_CONSUMER_OPTION,
      type: DATA_PERMISSION_LAKE_FORMATION_TYPE,
      nextToken: null,
    };
    let listSingleTableDataPermissionsResult = await listDataPermissions(listSingleTableDataPermissionsRequest);
    let singleTableDataPermission = [...listSingleTableDataPermissionsResult.dataPermissionList];
    while (listSingleTableDataPermissionsResult.nextToken != null) {
      listSingleTableDataPermissionsRequest.nextToken = listSingleTableDataPermissionsResult.nextToken;
      listSingleTableDataPermissionsResult = await listDataPermissions(listSingleTableDataPermissionsRequest);
      singleTableDataPermission.push(...listSingleTableDataPermissionsResult.dataPermissionList);
    }
    dataPermissionList.push(...singleTableDataPermission);

    this.setState({
      dataPermissionList: dataPermissionList,
    });
  };

  // Get the catalog for the current dataset
  fetchCatalog = async () => {
    let catalog;
    try {
      catalog = await listCatalogs({
        Filter: {
          CatalogKeyList: [
            {
              CatalogId: this.state.dataset.IdInfo.CatalogId,
              Region: this.state.dataset.IdInfo.Region,
            },
          ],
        },
      });
    } catch (e) {
      console.log('Could not load catalog for dataset: ' + this.state.dataset);
      catalog = null;
    }
    this.setState({
      catalog: catalog?.CatalogInfoList[0],
      catalogLoading: false,
    });
  };

  fetchDetails = async () => {
    const dataSetDetails = await dataSetDetail({
      Filter: {
        IdList: [this.props.match.params.id],
      },
    });
    // we reverse the version list so that the newest versions show first
    const formattedOptions = this.getFormattedOptions(dataSetDetails.DataSetDetailList);

    const noError = formattedOptions.length > 0;

    if (noError) {
      formattedOptions[0].label = formattedOptions[0].label + ' (latest)';
    }

    if (dataSetDetails.NextToken != null) {
      formattedOptions.push({
        id: this.LOAD_MORE_DATASETS_OPTION_ID,
        label: 'Load more...',
        item: null,
      });
    }

    if (!noError) {
      this.setState({
        datasetDetailsLoading: false,
        notifications: [
          {
            type: 'error',
            content: `Failed to load dataset details.`,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
    }

    const newStatus = noError ? 'finished' : 'error';
    const newOption = noError ? formattedOptions[0] : null;
    this.setState({
      datasetDetailsLoading: false,
      options: formattedOptions,
      mostRecentOption: newOption,
      status: newStatus,
      option: null,
      curNextToken: dataSetDetails.NextToken,
    });
  };

  getDropdownOptions() {
    if (!this.props.allowListed) {
      return [
        {
          text: 'Request access',
          loading: this.state.catalogLoading || this.state.datasetDetailsLoading,
          onItemClick: () => this.setState({ redirect: { pathname: '/onboarding' } }),
          variant: 'primary',
        },
      ];
    }

    if (!(this.state.roleProperties && this.state.dataPermissionList)) {
      return [
        {
          text: 'Loading...',
          disabled: true,
          loading: true,
        },
      ];
    }
    let dropdowns = [];
    if (this.userOwnsDataset() || isDataLakeAdmin(this.props.activeGroup)) {
      dropdowns = dropdowns.concat([
        {
          text: 'Sync dataset',
          onItemClick: this.syncDataset,
          loading: this.state.buttonLoading,
        },
      ]);
    }

    console.log(this.state.dataset);

    if (this.state.dataset?.SupportedAccessTypes?.includes('IAM')) {
      dropdowns = dropdowns.concat(this.getIamDropdown());
    }
    if (this.state.dataset?.SupportedAccessTypes?.includes('LakeFormation')) {
      dropdowns = dropdowns.concat(this.getLakeFormationDropdown());
    }
    if (this.state.dataset?.SupportedAccessTypes == null || this.state.dataset?.SupportedAccessTypes == 'Galaxi') {
      dropdowns = dropdowns.concat(this.getGalaxiDropdown());
    }

    return dropdowns;
  }

  getIamDropdown() {
    return [
      {
        text: 'IAM access',
        loading: this.state.catalogLoading || this.state.datasetDetailsLoading,
        onItemClick: this.handleAction,
        items: [
          {
            text: 'Add to cart',
            disabled: this.props.cartItemIds.includes(this.state.dataset?.Id),
            variant: 'normal',
            id: 'IAM',
          },
        ],
      },
    ];
  }

  getLakeFormationDropdown() {
    const lakeFormationAccess = !(
      this.state.dataPermissionList === undefined || this.state.dataPermissionList.length === 0
    );
    return [
      {
        text: 'Lake Formation access',
        loading: this.state.catalogLoading || this.state.datasetDetailsLoading,
        onItemClick: this.handleAction,
        items: [
          {
            text: 'Request access',
            disabled: lakeFormationAccess || this.state.dataset?.TableState == TABLE_STATUS_DEPRECATED,
            variant: 'normal',
            id: 'LakeFormation',
          },
          {
            text: 'View dataset permission',
            disabled: !lakeFormationAccess,
            variant: 'normal',
            id: 'LakeFormation',
          },
        ],
      },
    ];
  }

  getGalaxiDropdown() {
    return [
      {
        text: 'Galaxi dataset access',
        onItemClick: this.handleAction,
        items: [
          {
            text: 'Request access',
            variant: 'normal',
            id: 'Galaxi',
          },
        ],
      },
    ];
  }

  async handleIamRequest() {
    const flattenedDataset = flattenItem(this.state.dataset, null, this.state.catalog);
    this.props.addToCart([flattenedDataset]);
  }

  async handleLakeFormationRequest() {
    if (this.state.dataPermissionList?.length !== 0) {
      this.redirectViewDataPermissions();
    }
    // otherwise, request access
    else {
      this.redirectLakeFormationRequest();
    }
  }

  async handleGalaxiRequest() {
    this.setState({
      accessSelectVisible: true,
    });
  }

  syncDataset = async () => {
    this.setState({
      buttonLoading: true,
    });
    try {
      let syncRequest = {
        IdList: [this.props.match.params.id],
      };
      await syncDataSets(syncRequest);
      await this.handleRefresh();
      this.setState({
        notifications: [
          {
            type: 'success',
            content: `Successfully synced dataset`,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
        buttonLoading: false,
      });
    } catch (err) {
      this.setState({
        notifications: [
          {
            type: 'error',
            content: `Failed to sync dataset ` + err.message,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
        buttonLoading: false,
      });
    }
  };

  handleAction = async (e) => {
    if (e.detail.id === 'IAM') {
      await this.handleIamRequest();
    } else if (e.detail.id === 'LakeFormation') {
      this.handleLakeFormationRequest();
    } else if (e.detail.id == 'Galaxi') {
      this.handleGalaxiRequest();
    }
  };

  async submitAccessRequest() {
    this.setState({ requestSending: true });

    try {
      await editDataLakeRole({
        groupId: this.props.activeGroup,
        datasetsToAdd: [this.props.match.params.id],
      });
      await this.loadRoleProperties();
    } catch (err) {
      this.setState({
        notifications: [
          {
            type: 'error',
            content: `Failed to submit the access request.`,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
        requestSending: false,
      });
      return;
    }

    this.setState({
      notifications: [
        {
          type: 'success',
          content: `Successfully submitted the access request. This will remain pending until the data owners approve or deny the request.`,
          dismissible: true,
          onDismiss: () => this.setState({ notifications: [] }),
        },
      ],
      requestSending: false,
    });
  }

  // Note that the option to load more datasets will not be listed if the
  // NextToken is null.
  handleSelectChange = async (e) => {
    const selectedOption = e.detail.selectedOption;
    if (selectedOption != null && selectedOption.id == this.LOAD_MORE_DATASETS_OPTION_ID) {
      const combinedOptions = [];
      for (let i = 0; i < this.state.options.length - 1; i++) {
        combinedOptions.push(this.state.options[i]);
      }

      const response = await dataSetDetail({
        NextToken: this.state.curNextToken,
      });

      combinedOptions.push(...this.getFormattedOptions(response.DataSetDetailList));

      if (response.NextToken != null) {
        combinedOptions.push(this.state.options[this.state.options.length - 1]);
      }

      // Note that option should remain unchanged
      this.setState({
        options: combinedOptions,
        curNextToken: response.NextToken,
        option: combinedOptions[this.state.options.length - 1],
      });
    } else {
      this.setState({ option: e.detail.selectedOption });
    }
  };

  redirectViewDataPermissions() {
    let to: {};
    if (this.state.dataPermissionList == undefined || this.state.dataPermissionList?.length > 1) {
      to = {
        pathname: Page.MY_DATASETS,
      };
    } else {
      to = {
        pathname: Page.MY_DATASETS + '/' + this.state.dataPermissionList[0]?.dataPermissionId,
      };
    }
    this.setState({ redirect: to });
  }

  redirectLakeFormationRequest() {
    const id = this.props.match.params.id;
    const catalogId = this.state.dataset?.IdInfo.CatalogId;
    const databaseName = this.state.dataset?.IdInfo.DatabaseName;
    const region = this.state.dataset?.IdInfo.Region;
    const tableName =
      this.state.dataset && this.state.dataset.IdInfo && this.state.dataset.IdInfo.TableName
        ? this.state.dataset.IdInfo.TableName
        : '';
    const to = {
      pathname: Page.CREATE_LAKE_FORMATION_PERMISSIONS,
      state: {
        tableInfo: { id },
        tableName,
        databaseName,
        catalogId,
        region,
      },
    };
    this.setState({ redirect: to });
  }

  redirectSubscribe() {
    const id = this.props.match.params.id;
    const dataSetName = this.state.dataset && this.state.dataset.DataSetName ? this.state.dataset.DataSetName : '';
    const tableName =
      this.state.dataset && this.state.dataset.IdInfo && this.state.dataset.IdInfo.TableName
        ? this.state.dataset.IdInfo.TableName
        : '';
    const to = {
      pathname: Page.CREATE_SUBSCRIPTION,
      state: {
        tableInfo: { id },
        tableName,
        selectedTables: [{ id, description: tableName, label: dataSetName }],
      },
    };
    this.setState({ redirect: to });
  }

  // open up the modal view to edit the dataset metadata
  editDataset() {
    this.setState({
      editDataSetModalVisible: true,
    });
  }

  closeEditModal() {
    this.setState({
      editDataSetModalVisible: false,
    });
  }

  notifyEditSuccess = async () => {
    await this.handleRefresh();
    this.setState({
      notifications: [
        {
          type: 'success',
          content: `Successfully edited the dataset.`,
          dismissible: true,
          onDismiss: () => this.setState({ notifications: [] }),
        },
      ],
    });
  };

  notifyEditFailure = async () => {
    await this.handleRefresh();
    this.setState({
      notifications: [
        {
          type: 'error',
          content: `Failed to edit the dataset.`,
          dismissible: true,
          onDismiss: () => this.setState({ notifications: [] }),
        },
      ],
    });
  };

  render() {
    // if the dataset does not exist, OR
    // if the dataset is private and the active user is not the owner,
    // then show an error.
    if (!this.state.dataset || (this.state.dataset.DataClassification == 'Private' && !this.userOwnsDataset())) {
      return (
        <>
          <h2>This dataset does not exist or you do not have permissions to view it</h2>
          <br />
          The given dataset ID <b>{this.props.match.params.id}</b> is not valid. <br />
          Please contact the provider or verify the URL for mistakes and try again.
        </>
      );
    }

    let header;
    if (this.state.dataset.DataSetName) {
      header = this.state.dataset.DataSetName;
    } else {
      header = <Spinner size='big' />;
    }

    let version;
    if (this.state.status === 'error') {
      version = 'No metadata available';
    } else if (this.state.option == null) {
      if (this.state.mostRecentOption == null) {
        // If everything is not loaded yet
        version = <Spinner size='normal' />;
      } else {
        // If we have not selected an option.  Default to most recent
        version = 'Metadata version ' + this.state.mostRecentOption.id;
      }
    } else {
      version = 'Metadata version ' + this.state.option.id;
    }

    return (
      <>
        <Flashbar items={this.state.notifications} />
        {this.state.redirect && <Redirect push to={this.state.redirect} />}

        {this.state.editDataSetModalVisible && (
          <DatasetDetailsEditModal
            visible={this.state.editDataSetModalVisible}
            dismiss={this.closeEditModal}
            notifySuccess={this.notifyEditSuccess}
            notifyFailure={this.notifyEditFailure}
            dataset={this.state.dataset}
            activeGroup={this.props.activeGroup}
          />
        )}

        <SpaceBetween size='l' direction='vertical'>
          <DetailsPageHeader
            description={<DataBadges dataset={this.state.dataset} businessGlossaries={this.state.businessGlossaries} />}
            header={header}
            buttons={this.getDropdownOptions()}
            editButton={
              this.props.allowListed && this.state?.dataset?.Owners
                ? {
                    text: 'Edit',
                    hidden: !this.userOwnsDataset(),
                    onItemClick: this.editDataset,
                    loading: false,
                  }
                : null
            }
            versionHeader={version}
            cti={() => this.getCti()}
            versionSelect={
              <Select
                options={this.state.options}
                selectedOption={this.state.option}
                loadingText='Loading...'
                placeholder='Metadata history'
                statusType={this.state.status}
                errorText='Error: Unable to load metadata history.'
                onChange={this.handleSelectChange}
                ariaRequired={true}
              />
            }
          />
          <div>
            {(this.state.option == null &&
              this.state.mostRecentOption &&
              this.versionDetail(this.state.mostRecentOption?.item)) ||
              this.versionDetail(this.state.option?.item)}
          </div>
        </SpaceBetween>

        {this.state.accessSelectVisible !== undefined && this.state.accessSelectVisible && this.state.dataset && (
          <AccessSelectModal
            {...this.props}
            visible={this.state.accessSelectVisible}
            dismiss={() => {
              this.setState({
                accessSelectVisible: false,
              });
            }}
            tags={[]}
            fgaPolicies={[]}
            dataset={this.state.dataset}
            catalogName={'catalogName'}
          />
        )}
      </>
    );
  }

  // is the current user a member of a group that owns the dataset?
  userOwnsDataset() {
    let owners = this.state.dataset?.Owners;
    if (!this.state.dataset || !owners) return false;
    if (!this.props.userInfo) return false;
    if (this.props.userInfo && isDGSAdmin(this.props.userInfo.memberGroupIds)) return true;
    const groupOwner =
      !!this.props.userInfo.memberGroupIds && _.find(this.props.userInfo.memberGroupIds, (grp) => owners.includes(grp));
    return groupOwner
      ? true
      : !!this.props.userInfo.memberWorkspaceIds &&
          _.find(this.props.userInfo.memberWorkspaceIds, (wks) => owners.includes(wks));
  }

  private getCti = async () => {
    let metadataMap = await generateMetaDataMap(this.getArn());
    if (metadataMapHasCTI(metadataMap)) {
      return <></>;
    }
    if (this.state.catalogLoading) {
      return <Spinner size='normal' />;
    } else if (this.state.catalog == null) {
      return (
        <>
          {'Catalog CTI: '}
          <a href={DATA_LAKE_CTI_URL}>AWS/DataLake/Catalog Issues</a>
        </>
      );
    } else {
      return (
        <>
          {'Catalog CTI: '}
          <a href={getCtiUrlFromCti(this.state.catalog?.CTI)}>{this.state.catalog?.CTI}</a>
        </>
      );
    }
  };

  private getUsageStatistics() {
    return <UsageStatistics dataset={this.state.dataset} />;
  }
}
