import * as React from 'react';
import { useState } from 'react';

import { useCollection } from '@amzn/awsui-collection-hooks';

import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  Table,
  TextFilter,
  Button,
  ButtonDropdown,
  ColumnLayout,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { StatusIcon } from './statusIcon';
import { defaultWrapLinesPreference, paginationLabels } from 'src/commons/tables';
import { DATA_PERMISSION_LAKE_FORMATION_TYPE } from 'src/commons/constants';

export const typeToHumanReadableObject = {
  LF: 'Lake Formation',
  IAM: 'IAM',
  'LF-EMR': 'Lake Formation - EMR',
  S3: 'S3',
  REDSHIFT: 'Redshift',
  glueLF: 'Lake Formation',
  redshift: 'Redshift',
  glue: 'IAM',
};

export const FilterRamResource = ({ resource }) => {
  if (resource.type === 'glue:Table') return resource.arn.split('table/')[1];
  if (resource.type === 'glue:Database') return resource.arn.split('/')[1];
  if (resource.type === 'glue:Catalog') return resource.arn.split(':')[4];
};

export const PageHeader = ({ buttons, header }) => {
  return (
    <div className='awsui-util-mb-m awsui-util-mt-xs'>
      <div className='awsui-util-action-stripe'>
        <div className='awsui-util-action-stripe-title'>
          <h2>{header}</h2>
        </div>
        <SpaceBetween direction='horizontal' size='m'>
          {buttons.map((button, key) =>
            !button.items ? (
              <Button
                href={button.href || ''}
                disabled={button.disabled || false}
                iconName={button.icon}
                onClick={button.onItemClick}
                key={key}
                loading={button.loading || false}
                variant={button.variant || 'normal'}
              >
                {button.text}
              </Button>
            ) : (
              <ButtonDropdown items={button.items} key={key} onItemClick={button.onItemClick} loading={button.loading}>
                {button.text}
              </ButtonDropdown>
            ),
          )}
        </SpaceBetween>
      </div>
    </div>
  );
};

export const DataPermissionInformation = ({ dataPermissionItem }) => (
  <div className='awsui-util-container'>
    <div className='awsui-util-container-header'>
      <h2>Dataset Permission Information</h2>
    </div>
    <ColumnLayout columns={4} variant='text-grid'>
      <div>
        <div className='awsui-util-label'>Owner ID</div>
        <div>{dataPermissionItem.ownerId}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Source Account</div>
        <div>{dataPermissionItem.catalogId}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Source Database</div>
        <div>{dataPermissionItem.databaseName}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Source Table</div>
        <div>{dataPermissionItem.tableName}</div>
      </div>
      <div>
        <div className='awsui-util-label'>DataLake Principal</div>
        <div>{dataPermissionItem.dataLakePrincipal}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Share Type</div>
        <div>{typeToHumanReadableObject[dataPermissionItem.type]}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Status</div>
        <StatusIcon status={dataPermissionItem.status} />
        <div>Date: {dataPermissionItem.dateActive}</div>
        {dataPermissionItem.dateInActive !== undefined && dataPermissionItem.dateInActive !== null && (
          <div>Date Inactive: {dataPermissionItem.dateInActive}</div>
        )}
      </div>
      <div>
        <div className='awsui-util-label'>Audit Status</div>
        <StatusIcon status={dataPermissionItem.auditStatus} />
        <div>Date: {dataPermissionItem.dateOfLastAudit}</div>
      </div>
    </ColumnLayout>
  </div>
);

export const DataPermissionLakeFormation = ({ dataPermissionItem }) => (
  <div className='awsui-util-container'>
    <div className='awsui-util-container-header'>
      <h2>Lake Formation Permissions</h2>
    </div>
    <ColumnLayout columns={2} borders='horizontal'>
      <div>
        <div className='awsui-util-label'>Principal</div>
        <div>{dataPermissionItem.dataLakePrincipal}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Principal type</div>
        <div>{/^\d+$/.test(dataPermissionItem.dataLakePrincipal) ? 'AWS account' : 'IAM role'}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Resource</div>
        {/*We mostly only support column access we can revisit to change this*/}
        <div>Column</div>
      </div>
      <div>
        <div className='awsui-util-label'>Resource name</div>
        <div>Database: {dataPermissionItem.databaseName}</div>
        <div>Table: {dataPermissionItem.tableName}</div>
        <div>
          Included columns:{' '}
          {dataPermissionItem.columns !== undefined && dataPermissionItem.columns !== null
            ? dataPermissionItem.columns.join(', ')
            : 'All'}
        </div>
      </div>
      {dataPermissionItem.type === DATA_PERMISSION_LAKE_FORMATION_TYPE && (
        <>
          <div>
            <div className='awsui-util-label'>Permissions</div>
            <div>
              {dataPermissionItem.audit !== undefined
                ? JSON.parse(dataPermissionItem.audit.PermissionsString).join(', ')
                : ''}
            </div>
          </div>
          <div>
            <div className='awsui-util-label'>Permissions Grantable</div>
            <div>
              {dataPermissionItem.audit !== undefined
                ? JSON.parse(dataPermissionItem.audit.PermissionsWithGrantOptionString).join(', ')
                : ''}
            </div>
          </div>
        </>
      )}
    </ColumnLayout>
  </div>
);

export const DataPermissionRAMResourceShare = ({ ramResourceShare }) => {
  if (ramResourceShare === undefined) {
    return (
      <div className='awsui-util-container'>
        <div className='awsui-util-container-header'>
          <h2>RAM Share Summary</h2>
        </div>
        <ColumnLayout columns={1} variant='text-grid'>
          <div data-awsui-column-layout-root='true'>
            <div>
              <div>No Data Stored. Run Audit.</div>
            </div>
          </div>
        </ColumnLayout>
      </div>
    );
  }
  return (
    <div className='awsui-util-container'>
      <div className='awsui-util-container-header'>
        <h2>RAM Share Summary</h2>
      </div>
      <ColumnLayout columns={4} variant='text-grid'>
        <div>
          <div className='awsui-util-label'>Name</div>
          <div>{ramResourceShare.name}</div>
        </div>
        <div>
          <div className='awsui-util-label'>Owner</div>
          <div>{ramResourceShare.owningAccountId}</div>
        </div>
        <div>
          <div className='awsui-util-label'>Created on</div>
          <div>
            {new Date(ramResourceShare.creationTime).toISOString().replace('-', '/').split('T')[0].replace('-', '/')}
          </div>
        </div>
        <div>
          <div className='awsui-util-label'>Status</div>
          <StatusIcon status={ramResourceShare.status} />
        </div>
        <div>
          <div className='awsui-util-label'>Id</div>
          <div>{ramResourceShare.resourceShareArn.split('/')[1]}</div>
        </div>
        <div>
          <div className='awsui-util-label'>ARN</div>
          <div>{ramResourceShare.resourceShareArn}</div>
        </div>
        <div>
          <div className='awsui-util-label'>Allow external principals</div>
          <div>{ramResourceShare.allowExternalPrincipals === true ? 'Yes' : 'No'}</div>
        </div>
      </ColumnLayout>
    </div>
  );
};

export const DataPermissionConsumers = ({ dataPermissions, columnDefinitions, dataPermissionsLoading }) => {
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
  });

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(dataPermissions, {
    filtering: {
      empty: (
        <div className='awsui-util-t-c'>
          <p className='awsui-util-mb-s'>No dataset permissions to display.</p>
        </div>
      ),
      noMatch: '',
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  return (
    <Table
      {...collectionProps}
      loadingText='Loading Consumers...'
      columnDefinitions={columnDefinitions}
      items={items}
      wrapLines={false}
      resizableColumns={true}
      header={
        <h2>
          Consumers
          <span className='awsui-util-header-counter'>{` (${dataPermissions.length})`}</span>
        </h2>
      }
      loading={dataPermissionsLoading}
      filter={
        <TextFilter
          {...filterProps}
          filteringAriaLabel='Filter resources'
          filteringPlaceholder='Find resources'
          countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
        />
      }
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      preferences={
        <CollectionPreferences
          title={'Preferences'}
          confirmLabel={'Confirm'}
          cancelLabel={'Cancel'}
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
          pageSizePreference={{
            title: 'Page size',
            options: [
              { value: 10, label: '10 items' },
              { value: 20, label: '20 items' },
              { value: 50, label: '50 items' },
            ],
          }}
          wrapLinesPreference={defaultWrapLinesPreference}
        />
      }
    />
  );
};

export const DataPermissionRAMResources = ({ ramResources }) => {
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
  });

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(ramResources, {
    filtering: {
      empty: (
        <div className='awsui-util-t-c'>
          <p className='awsui-util-mb-s'>No dataset permissions to display.</p>
        </div>
      ),
      noMatch: '',
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  return (
    <Table
      {...collectionProps}
      loadingText='Loading Resources...'
      columnDefinitions={[
        {
          id: 'resourceId',
          header: 'Resource ID',
          cell: (item) => <FilterRamResource resource={item} />,
          minWidth: 200,
        },
        {
          id: 'type',
          header: 'Resource type',
          cell: (item) => item['type'],
          minWidth: 120,
        },
        {
          id: 'status',
          header: 'Status',
          cell: (item) => <StatusIcon status={item['status']} />,
          minWidth: 120,
        },
      ]}
      items={items}
      wrapLines={false}
      resizableColumns={true}
      header={
        <h2>
          RAM Shared Resources
          <span className='awsui-util-header-counter'>{` (${ramResources.length})`}</span>
        </h2>
      }
      empty={
        <div className='awsui-util-t-c'>
          <p className='awsui-util-mb-s'>
            No Resources to display. Run Audit. If still no Resources, Check the RAM console and Re-Request.
          </p>
        </div>
      }
      filter={
        <TextFilter
          {...filterProps}
          filteringAriaLabel='Filter resources'
          filteringPlaceholder='Find resources'
          countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
        />
      }
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      preferences={
        <CollectionPreferences
          title={'Preferences'}
          confirmLabel={'Confirm'}
          cancelLabel={'Cancel'}
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
          pageSizePreference={{
            title: 'Page size',
            options: [
              { value: 5, label: '5 items' },
              { value: 10, label: '10 items' },
              { value: 20, label: '20 items' },
            ],
          }}
          wrapLinesPreference={defaultWrapLinesPreference}
          visibleContentPreference={{
            title: 'Visible content',
            options: [
              {
                label: 'Columns',
                options: [
                  {
                    id: 'resourceId',
                    label: 'Resource ID',
                    editable: false,
                  },
                  {
                    id: 'type',
                    label: 'Resource type',
                    editable: true,
                  },
                  {
                    id: 'status',
                    label: 'Status',
                    editable: true,
                  },
                ],
              },
            ],
          }}
        />
      }
    />
  );
};
